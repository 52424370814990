<template>
  <!-- 数据统计分析 -->
  <div class="container">
    <a-page-header :title="title" />

    <div class="main-content">
      <div class="list" v-if="info">
        <div class="item">
          <div class="title">总访问量</div>
          <div class="number">{{ info.totalVisitCount }}</div>
          <div class="value">
            <span class="label">今日访问量</span>
            <span class="value-num">{{ info.totalVisitDay }}</span>
          </div>
        </div>
        <div class="item">
          <div class="title">总支付笔数</div>
          <div class="number">{{ info.totalOrder }}</div>
          <div class="value value-flex">
            <div class="value-item">
              <span class="label">验收单数</span>
              <span class="value-rate">{{ info.finishOrder }}</span>
            </div>
            <div class="value-item">
              <span class="label">转化率</span>
              <span class="value-rate">{{ info.translateRate }}%</span>
            </div>
          </div>
        </div>
        <div class="item">
          <div class="title">总业绩额</div>
          <a-statistic prefix="￥" :value="info.totalPaidFee" />
          <div class="value">
            <span class="label">今日业绩额</span>
            <a-statistic
              class="value-price"
              prefix="￥"
              :value="info.thedayPaidFee"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      title: '',
      info: null
    }
  },
  created() {
    this.title = this.$getPageTitle()
    this.getStatistics()
  },
  methods: {
    getStatistics() {
      this.$axios.getStatistics().then((res) => {
        this.info = res.data.data
      })
    }
  }
}
</script>

<style scoped>
.list {
  display: flex;
}

.item {
  width: 269px;
  height: 152px;
  background-color: #fff;
  padding: 20px 24px;
}

.item:not(:last-child) {
  margin-right: 24px;
}

.item .title,
.item .value {
  font-size: 14px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.45);
}

.item .number {
  font-size: 30px;
  color: rgba(0, 0, 0, 0.85);
  line-height: 38px;
  margin-top: 4px;
}

.item .value {
  margin-top: 16px;
  padding-top: 9px;
  border-top: 1px solid #e8e8e8;
  display: flex;
  align-items: center;
}

.value-flex {
  display: flex;
  justify-content: space-between;
}

.value-label {
  color: rgba(0, 0, 0, 0.65);
}

.value-num,
.value-rate,
.item .value-price {
  margin-left: 8px;
}

.value-num,
.value-rate,
.item .value-price >>> .ant-statistic-content,
.item .value-price >>> .ant-statistic-content-value-decimal {
  color: rgba(0, 0, 0, 0.85);
  font-weight: normal;
  font-size: 14px;
}
</style>
